import Swiper, {Navigation, EffectFlip, A11y, Autoplay} from 'swiper';

Swiper.use([Navigation, EffectFlip, A11y, Autoplay]);

function swiperUsp() {
  var hasSwipers = document.getElementsByClassName('js-swiper-usp');
  if (hasSwipers.length > 0) {
    var swiper = new Swiper('.js-swiper-usp', {
      // Optional parameters
      loop: true,
      autoplay: {
        delay: 5000,
        stopOnLastSlide: true,
        disableOnInteraction: true
      },
      centeredSlides: true,
      speed: 600,
      autoHeight: true,
      effect: 'flip',
      grabCursor: true,
      
      
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-btn-next',
        prevEl: '.swiper-btn-prev',
      },
    });
  }
}

export default swiperUsp;