// CSS Imports
import '../css/style.pcss';

// NPM Imports
import 'lazysizes';

//ALPINEJS
import 'alpinejs';

//COMPONENTS IMPORTS
import swiperUsp from './components/swiper-usp';

//INIT COMPONENTS
swiperUsp();
